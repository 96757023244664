<template>
  <a-spin :spinning='spinning'>
    <router-header :routerHeaderInfo="routerHeaderInfo" />

    <div class="title-mark">基础信息</div>

    <a-form ref="formRef"
            :model="form"
            :rules="rules"
            scrollToFirstError
            :labelCol="{style: 'width: 96px'}">

      <a-form-item label="专栏名称"
                   name="name">
        <a-input v-model:value="form.name"
                 placeholder="请输入专栏名称"
                 style="width: 320px">
          <template #suffix>
            <span class="text-color-999">{{form.name.length}} / 60</span>
          </template>
        </a-input>
      </a-form-item>

      <a-form-item label="专栏封面"
                   name="cover">
        <upload-image :apiName="'live'"
                      desc="建议尺寸750*422或比例16:9，小于2M的JPG、PNG格式图片"
                      :imageUrl="form.cover"
                      @child-image="handleImgUrl" />
      </a-form-item>

      <a-form-item class="custom-form-item"
                   label="专栏简介"
                   name="summary">
        <a-textarea v-model:value="form.summary"
                    showCount
                    :maxlength="300"
                    style="width: 464px"
                    :autoSize="{ minRows: 6, maxRows: 6 }">
        </a-textarea>
      </a-form-item>

      <div class="save-btn mt-80 mx-auto">
        <a-button class="btn"
                  type="primary"
                  :loading="submitLoading"
                  v-debounce="{method: saveLive, delay: 300}">
          {{$route.query.setId ? '保存' : '创建专栏' }}
        </a-button>
      </div>
    </a-form>
  </a-spin>

</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import { message } from "ant-design-vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

import liveApi from "@/service/api/live";

import RouterHeader from "@/components/RouterHeader";
import UploadImage from "@/components/UploadImage";

import "@/styles/components/content-styles.less";

export default defineComponent({
  name: "LiveOpenClassCreate",

  components: {
    RouterHeader,

    UploadImage,
  },

  setup() {
    const spinning = ref(true);

    const route = new useRoute();
    const router = new useRouter();
    const { setId } = route.query;

    const routerHeaderInfo = reactive([
      {
        path: "/marketingUtil/liveOpenClassIndex",
        name: "直播专栏",
        query: {
          active: "column",
        },
      },
      { name: "新建专栏" },
    ]);

    const LIVE_COVER_DEFAULT = process.env.VUE_APP_LIVE_COVER_DEFAULT;

    const form = reactive({
      name: "",
      cover: LIVE_COVER_DEFAULT,
      summary: "",
    });

    const rules = {
      name: [
        {
          required: true,
          whiteSpace: true,
          message: "专栏名称不能为空",
        },
        {
          max: 60,
          message: "专栏名称长度不能超过60字",
        },
      ],
      cover: [
        {
          required: true,
          message: "请上传专栏封面",
        },
      ],
      summary: [
        {
          required: true,
          whiteSpace: true,
          message: "专栏简介不能为空",
        },
      ],
    };

    // 判断为新建or编辑

    const getFormInfo = async () => {
      const res = await liveApi.getLiveRoomSet({
        id: setId,
      });

      const { id, name, summary, cover } = res;

      _.assign(form, {
        id,
        name,
        summary,
        cover,
      });
    };

    const initData = async () => {
      if (!setId) {
        spinning.value = false;
        return;
      }

      routerHeaderInfo[routerHeaderInfo.length - 1].name = "编辑专栏";

      await getFormInfo();
      spinning.value = false;
    };

    // 专栏封面
    const handleImgUrl = function (imgurl) {
      form.cover = imgurl;
    };

    // 新建专栏
    const submitLoading = ref(false);

    const formRef = ref();

    const timeError = ref("");

    const saveLive = async () => {
      submitLoading.value = true;

      formRef.value
        .validate()
        .then(async () => {
          if (route.query.setId) {
            form.id = route.query.setId;

            await liveApi.updateLiveRoomSet(form);
            message.success("编辑成功");
            router.push({
              name: "liveColumn_statistic",
              query: {
                setId: route.query.setId,
              },
            });
            return;
          }

          const queryList = await liveApi.createLiveRoomSet(form);
          message.success("创建成功");

          const { id: setId } = queryList;
          router.push({
            name: "liveColumn_create_success",
            query: {
              setId,
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });

      submitLoading.value = false;
    };

    initData();

    return {
      lodash: _,
      routerHeaderInfo,

      spinning,
      form,
      rules,

      handleImgUrl,

      formRef,
      submitLoading,
      saveLive,

      timeError,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const length = vm.routerHeaderInfo.length;
      const path = vm.routerHeaderInfo[length - 2].path;
      if (!_.includes(["/", path], from.path)) {
        const {
          path,
          meta: { name },
          query,
        } = from;

        _.assign(vm.routerHeaderInfo[length - 2], {
          path,
          name,
          query,
        });
      }
    });
  },
});
</script>

<style lang='less' scoped>
:deep(.ant-upload.ant-upload-select-picture-card) {
  width: 240px;
  aspect-ratio: 16 / 9;
}

.custom-form-item {
  :deep(.ant-form-explain) {
    position: absolute;
    bottom: 12px;
  }
}
</style>
